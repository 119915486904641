import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useAppContext } from "../../context/AppContext";
import useGenericGet from "../../hooks/common/useGenericGet";
import {
  DEVELOPMENT_PERMIT_STATUS_API,
  GRIEVANCES_STATUS_API,
  REVENUE_SOURCE_STATUS_API,
  SERVICE_APPLICATION_STATUS_API,
} from "../../constants/api";
import { AlertMessage } from "../common/alert/AlertMessage";
import {
  STATUS_DEVELOPMENT_CONTROL,
  STATUS_GRIEVANCE,
  STATUS_REVENUE_SOURCE,
  STATUS_SERVICE_APPLICATION,
} from "../../constants/webapp";
import { inquiryUrl } from "../../constants/links";
import { Link } from "react-router-dom";

export const permitTypes = [
  {
    id: "building-permit",
    name: "Building Permit",
  },
  {
    id: "development-permit",
    name: "Development Permit",
  },
  {
    id: "telecommunication-mast-permit",
    name: "Telecommunication Mast Permit",
  },
  {
    id: "occupation-permit",
    name: "Occupation Permit",
  },
  {
    id: "demolition-permit",
    name: "Demolition Permit",
  },
  {
    id: "subdivision-consolidation-permit",
    name: "Subdivision Consolidation Permit",
  },
  {
    id: "condominium-permit",
    name: "Condominium Permit",
  },
  {
    id: "excavation-permit",
    name: "Excavation-permit",
  },
  {
    id: "hoarding-permit",
    name: "Hoarding Permit",
  },
  {
    id: "chainlink-permit",
    name: "Chainlink Permit",
  },
  {
    id: "renovation-permit",
    name: "Renovation Permit",
  },
  {
    id: "lease-application",
    name: "Lease Application",
  },
  {
    id: "change-of-land-use-permit",
    name: "Change Of Land Use Permit",
  },
  {
    id: "outdoor-advertisement-permit",
    name: "Outdoor Advertisement Permit",
  },
];

export const revenueSources = [
  "Trade License",
  "Operational Permit",
  "Ground Rent",
  "LHT",
  "LST",
  "Market Rent",
  "Outdoor Advertising",
  {
    name: "Property Tax",
    id: "property",
  },
  {
    name: "Property Valuation",
    id: "property_valuations",
  },
  "Rent And Rates",
  "Transport",
  {
    name: "Park User Fees",
    id: "commercial_vehicle",
  },
];

export const services = [
  { id: "ammendment", name: "Amendment" },
  "Reassessments",
  "Business Transfers",
  "Business Deactivation",
  "Objections",
];

function getPermitStatus(status) {
  var permitStatusData = {};
  // for development control
  if (status === STATUS_DEVELOPMENT_CONTROL) {
    permitStatusData = {
      api: DEVELOPMENT_PERMIT_STATUS_API,
      title: "Permit",
      selectLabel: "Permit Type",
      inputLabel: "Service Request Number",
      inputId: "serial_number",
      selectId: "permit_type",
      types: permitTypes,
    };
    // for revenue source
  } else if (status === STATUS_REVENUE_SOURCE) {
    permitStatusData = {
      api: REVENUE_SOURCE_STATUS_API,
      title: "Revenue Source",
      selectLabel: "Revenue Source",
      inputLabel: "Application Number",
      inputId: "application_number",
      selectId: "table_name",
      types: revenueSources.map((revenueSource) => {
        return {
          id:
            revenueSource?.id ||
            revenueSource.replace(" ", "_").toLocaleLowerCase(),
          name: revenueSource?.name || revenueSource,
        };
      }),
    };
    // for service application
  } else if (status === STATUS_SERVICE_APPLICATION) {
    permitStatusData = {
      api: SERVICE_APPLICATION_STATUS_API,
      title: "Service",
      selectLabel: "Service Type",
      inputLabel: "Application Number",
      inputId: "application_number",
      selectId: "service_name",
      types: services.map((item) => {
        return {
          id: item.id || item.replace(" ", "_").toLocaleLowerCase(),
          name: item.name || item,
        };
      }),
    };
  }
  // for inquiry status
  else if (status === STATUS_GRIEVANCE) {
    permitStatusData = {
      api: GRIEVANCES_STATUS_API,
      title: "Grivance",
      inputLabel: "Ticket Number",
      inputId: "ticket",
    };
  }
  return { permitStatusData };
}

export default function PermitStatus() {
  const [value, setValue] = React.useState(null);
  const [permitType, setPermitType] = React.useState(null);

  const { contextData, updateContext } = useAppContext();
  var { openPermit, permitStatus } = contextData;

  const { permitStatusData } = getPermitStatus(permitStatus);

  const { loadData: getPermit, loading, data: permitData } = useGenericGet();

  const handleChange = (event) => {
    setValue({
      [event.target.name]: event.target.value,
    });
  };

  const setClose = () => {
    updateContext("openPermit", false);
  };

  const handleClose = () => {
    setClose();
  };

  // call api
  const handleCheck = () => {
    getPermit({
      // api: `/revenues/${permitType?.permit_type}s`,
      api: permitStatusData.api,
      params: {
        ...value,
        ...permitType,
      },
    });
  };

  return (
    <div>
      <Dialog open={openPermit} onClose={handleClose}>
        <DialogTitle>
          Check {permitStatusData.title} Application Status
        </DialogTitle>
        <DialogContent>
          <p className="font13 greyColor">
            To check you application status, please select your prefered{" "}
            {permitStatusData.selectLabel}, and <br />
            enter your{" "}
            <span className="semiBold">{permitStatusData.inputLabel}</span> to
            continue.
          </p>

          {permitStatusData.selectLabel && (
            <FormControl fullWidth sx={{ mt: 4 }}>
              <InputLabel>{permitStatusData.selectLabel}</InputLabel>
              <Select
                value={permitType && permitType[permitStatusData?.selectId]}
                name={permitStatusData.selectId}
                label={permitStatusData.selectLabel}
                required
                onChange={(event) =>
                  setPermitType({
                    [event.target.name]: event.target.value,
                  })
                }
              >
                {permitStatusData.types?.map((permitType) => (
                  <MenuItem sx={{ fontSize: 14 }} value={permitType.id}>
                    {permitType.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <TextField
            sx={{ mt: 3 }}
            autoFocus
            margin="dense"
            id="serial_number"
            label={permitStatusData.inputLabel}
            type="text"
            name={permitStatusData.inputId}
            fullWidth
            required
            onChange={handleChange}
          />
          {/* Status Details */}
          <div className="font13 flexCenter" style={{ marginTop: 10 }}>
            {/* Loading View */}
            {loading ? (
              <CircularProgress size="30px" />
            ) : (
              <AlertMessage
                message={permitData?.status || permitData?.error}
                type={(permitData?.status && "success") || "error"}
              />
            )}
          </div>

          {/* for grievances */}
          {permitStatus === STATUS_GRIEVANCE && (
            <Button
              variant="contained"
              fullWidth
              disableElevation
              onClick={handleClose}
            >
              <Link style={{ color: "white" }} className="x100" to={inquiryUrl}>
                Register Grivance
              </Link>
            </Button>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleCheck}>Check Status</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
